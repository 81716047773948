import './bootstrap';
import * as Sentry from "@sentry/vue";
import piniaPluginPersistedState from "pinia-plugin-persistedstate"
import { createApp, markRaw, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { createPinia } from 'pinia'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'

const pinia = createPinia()
pinia.use(piniaPluginPersistedState)

import.meta.glob([
  '../img/**',
])

createInertiaApp({
  resolve: (name) => resolvePageComponent(
    `./pages/${name}.vue`,
    import.meta.glob('./pages/**/*.vue')
  ),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(pinia)

    Sentry.init({
      app,
      dsn: "https://a7489e0a356df88fd6a4c01bca305192@o4507427102326784.ingest.de.sentry.io/4507427152658512",
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      environment: import.meta.env.VITE_APP_ENV,
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });

    app.mount(el)
  },
})

//const app = createApp(App)
//app.mount('#app')
